export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  REGISTRATION: '/registration',
  VERIFICATION_CODE: '/verify-mobile',
  VERIFY_EMAIL: '/verify-email',
  SETTINGS: '/settings',
  PUBLICATIONS: '/publications',
  ADD_PUBLICATION: '/add-publication',
};
