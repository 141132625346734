export const theme = {
  primary: '#003342',
  primaryHover: '#074A5E',
  secondary: '#c0d32c',
  secondaryHover: '#B3C332',
  secondaryLight: '#fcffe8',

  success: '#75aa12',
  error: '#ea2a2a',

  N1: '#ffffff',
  N2: '#f5f5f5',
  N3: '#eaeaea',
  N4: '#b8b2b2',
  N5: '#4f4f4f',
  N6: '#1d1617',

  fontHeading: "'Lato', sans-serif",
  fontBody: "'Montserrat', sans-serif",
};
